import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { makeGetInputStateByField } from '../../../redux/selectors/selectorIndex';

import InputRange from 'react-input-range';

import { 
  RangeContainer, 
  SliderContainer } from '../atomicStyledComponents/stylesIndex';

class RangeSlider extends PureComponent {
  constructor({ 
    id, options, 
    field, title, parent, 
    handleSlide, value,
    prenuvoID
  }) {
    super();
    this.id = id;
    this.prenuvoID = prenuvoID;
    this.field = field;
    this.title = title;
    this.parent = parent;
    this.handleSlide = handleSlide;
    this.minValue = options.lower;
    this.maxValue = options.upper;
    this.step = options.steps;
    this.nonzero = parent.includes("FamilyHistory.Parents");

    this.state = {
      loaded: false,
      lastStoredValue: (value
        ? value : null),
      value: value
        ? value : this.minValue };
      
    this.slideProps = {
      id: this.id, step: this.step,
      minValue: this.minValue,
      maxValue: this.maxValue,
      onChangeComplete: this.handleRangeChange };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.loaded) {
      return { loaded: nextProps.loaded }};
    return null;
  }

  handleRangeChange = val => {
    if ((!this.nonzero &&val >= 0) || (this.nonzero && val > 0)) {
      let strVal = val.toString();
      let payload = {f: this.props.field, c: val, p: this.state.lastStoredValue ? this.state.lastStoredValue : null}
      let valuePrev
      let action
      if(this.state.lastStoredValue){
        valuePrev = this.state.lastStoredValue
        action = "change"
      } else {
        valuePrev = null
        action = "add"
      }

      this.handleSlide(this.field, strVal, valuePrev, action, this.parent, this.prenuvoID, true, payload)
      this.setState(
        {lastStoredValue: ((Math.round(val * 10)) / 10)});
    }
  }

  onChange = val => {
  if ((!this.nonzero &&val >= 0) || (this.nonzero && val > 0)) {
      this.setState(
        { value: ((Math.round(val * 10)) / 10) })};
  }

  render() {
    return (
      <RangeContainer>
        <SliderContainer>
          <InputRange
            onChange={ this.onChange }
            value={ this.state.value } { ...this.slideProps } />
        </SliderContainer>
      </RangeContainer>
    )}
}

const makeMapStateToProps = _ => {
  const getRangeFromState = makeGetInputStateByField();

  const mapStateToProps = (state, props) => {
    const rangeVal = getRangeFromState(state, props);
    if (rangeVal.length > 0) {
      return { value: Number(rangeVal[0].value) }};
    return props };
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(RangeSlider);